import React, { useState, useEffect } from 'react';
import '../../App.scss';
import video from '../LoginAssets/video.mp4';
import logo from '../LoginAssets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { FaUserShield } from "react-icons/fa6";
import { BsFillShieldLockFill } from "react-icons/bs";
import { AiOutlineSwapRight } from "react-icons/ai";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import Axios from 'axios';

const RegisterBook = () => {
    const [bookNames, setBookNames] = useState([]); // Initialize as an empty array
    const [bookCategory, setBookCategory] = useState([]); // Initialize as an empty array
    const [book_name, setBookName] = useState('');
    const [book_category, setBookCategories] = useState('');
    const [author, setAuthor] = useState('');
    const [file, setChapterAudio] = useState(null);
    const [pay_status, setPayStatus] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        Axios.get('https://server.skizafm.com/api/v1/audioBook/get-book-name')
            .then((response) => {
                if (Array.isArray(response.data.data)) {
                    setBookNames(response.data.data); // Ensure response.data is an array
                } else {
                    console.log('Unexpected response format', response.data);
                }
            })
            .catch((error) => {
                console.log('Error fetching book names!', error);
            });
    }, []);

    useEffect(() => {
        Axios.get('https://server.skizafm.com/api/v1/audioBook/get-book-category')
            .then((response) => {
                if (Array.isArray(response.data.data)) {
                    setBookCategory(response.data.data); // Ensure response.data is an array
                } else {
                    console.log('Unexpected response format', response.data);
                }
            })
            .catch((error) => {
                console.log('Error fetching book categories!', error);
            });
    }, []);

    const createUser = (e) => {
        e.preventDefault(); // Prevent default form submission
        const formData = new FormData();
        formData.append('book_name', book_name);
        formData.append('book_category', book_category);
        formData.append('author', author);
        formData.append('file', file);
        formData.append('pay_status', pay_status);
    
        Axios.post('https://server.skizafm.com/api/v1/audioBook/book-register', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(() => {
            console.log('Book registered successfully!');
            navigate('/get-book');
        })
        .catch((error) => {
            console.log('Cannot register book!', error);
        });
    };

    return (
        <div className='registerPage flex'>
            <div className="container flex">
                <div className="videoDiv">
                    <video src={video} autoPlay loop muted></video>
                    <div className="textDiv">
                        <h2 className="title">Podcast App Admin Dashboard</h2>
                    </div>
                    <div className="footerDiv flex">
                        <p>Learn as you listen</p>
                        {/*<span className="text">Have an account?</span>
                        <Link to={'/'}><button className='btn'>Log In</button></Link>*/}
                    </div>
                </div>
                <div className="formDiv flex">
                   
                    <form onSubmit={createUser} className='form grid' encType="multipart/form-data">
                        <div className="inputDiv">
                            <label htmlFor="book_name">Book Category</label>
                            <div className="inputRegister flex">
                                <MdOutlineMarkEmailRead className='icon' />
                                <select id="book_category" name="book_category" onChange={(e) => setBookCategories(e.target.value)} required>
                                    <option value="" disabled selected>Select a book category</option>
                                    {bookCategory.map((book, index) => (
                                        <option key={book.id} value={book.name}>{book.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="inputDiv">
                            <label htmlFor="book_name">Book Name</label>
                            <div className="inputRegister flex">
                                <MdOutlineMarkEmailRead className='icon' />
                                <select id="book_name" name="book_name" onChange={(e) => setBookName(e.target.value)} required>
                                    <option value="" disabled selected>Select a book name</option>
                                    {bookNames.map((book, index) => (
                                        <option key={book.id} value={book.name}>{book.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="inputDiv">
                            <label htmlFor="author">Author</label>
                            <div className="inputRegister flex">
                                <MdOutlineMarkEmailRead className='icon' />
                                <input type="text" id='author' placeholder='Enter Author Name' onChange={(e) => setAuthor(e.target.value)} required />
                            </div>
                        </div>
                        <div className="inputDiv">
                            <label htmlFor="chapter_audio">Audio</label>
                            <div className="inputRegister flex">
                                <FaUserShield className='icon' />
                                <input type="file" id='chapter_audio' onChange={(e) => setChapterAudio(e.target.files[0])} required />
                            </div>
                        </div>
                        <div className="inputDiv">
                            <label htmlFor="pay_status">Pay Amount</label>
                            <div className="inputRegister flex">
                                <BsFillShieldLockFill className='icon' />
                                <input type="text" id='pay_status' placeholder='Enter Pay Amount for Book' onChange={(e) => setPayStatus(e.target.value)} required />
                            </div>
                        </div>
                        <button type='submit' className='btn flex'>
                            <span>Add Chapter </span>
                            <AiOutlineSwapRight className='icon' />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterBook;
